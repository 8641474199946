<template>
  <div id="app" class>
    <BaseHeader></BaseHeader>
    <div class="main-content">
      <router-view></router-view>
    </div>
    <div class="copywrite" :class="$route.path === '/'?'fixed':''">
      <a :href="info.url">{{info.putOnRecords}}</a>
      <div class="mt-5">免责声明 本网站内容均由用户自行上传，如权利人发现存在误传其他作品情形，请及时联系本站处理。</div>
      <div class="mt-5">客服热线：{{info.phone}}</div>
    </div>
    <!-- <BaseFooter></BaseFooter> -->
  </div>
</template>

<script>
import BaseHeader from "./BaseHeader.vue";
// import BaseFooter from "./BaseFooter.vue";
import { getOthers } from '@/api';
export default {
  components: {
    BaseHeader,
    // BaseFooter,
  },
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getOthers();
      this.info = res
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  height: 100vh;
  min-width: 1200px;
  width: 100%;
  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  position: relative;
  .main-content {
    min-height: calc(100% - 90px);
  }
}
.copywrite {
  text-align: center;
  padding: 12px 0;
  font-size: 16px;
      color: #ccc;
    background-color: #333;
  &.fixed {
    background-color: transparent;
    color: #ccc;
    position: absolute;
    bottom: 8px;
    right: 0;
    left:0;
    z-index: 999;
  }
}
.mt-5 {
  margin-top: 5px;
}
</style>
