import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI, {Message } from "element-ui";
import 'normalize.css';
import '@/icons' // icon
import './style/theme.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '@/mock'
import axiosInstance from '@/utils/request'
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI, { size: "small" });

Vue.config.productionTip = false;
Vue.directive('pending', {
  bind: (el) => {
    el.onclick = () => {
      Message.info('功能开发中~')
      return
    }
  }
})
Vue.directive('login', {
  bind: (el) => {
    el.onclick = () => {
      if (!store.state.userinfo) {
        store.commit('triggerLogin', true)
        return;
      }
    }
  }
})
VueClipboard.config.autoSetContainer = true
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
const render = (config) => {
  axiosInstance.init();
  Vue.prototype.$globalConfig = config
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

fetch(window.location.href.replace(window.location.hash, '') + '/static/config.json', {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
}).then(response => response.json()) // parses response to JSON
.then((res) => {
  window.globalConfig = res
   render(res);
});