<template>
  <!-- <div id="app"> -->
  <BaseMain></BaseMain>
  <!-- </div> -->
</template>

<script>
import BaseMain from "./layout/BaseMain.vue";
export default {
  components: {
    BaseMain,
  },
};
</script>

<style lang="scss">
@import "./style/base.scss";
.floor-wrapper {
  width: 1440px;
  margin: 0 auto;
  margin-bottom: 40px;
  &.mini {
    width: 1200px;
  }
}
.page-block {
  height: 70px;
}
.el-tabs__nav-wrap::after {
  height: 1px !important;
}
.checked {
  position: relative;
  border: 1px solid #f80000 !important;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    bottom: -2px;
    background-image: url("./assets/checked.png");
    background-size: 100% 100%;
  }
}
.el-popconfirm__main {
  margin: 8px 0;
}
.mt-25 {
  margin-top: 25px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
</style>
