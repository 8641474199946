import request from '@/utils/request';
export const API = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  GET_CODE: '/smssend',
  REGISTER: '/user/register',
  RESET_PASSWORD: '/ForgetPwd',
  GET_USER_INFO: '/user/home',
  SET_USER_INFO: 'user/InformationEdit',
  UPLOAD_USER_AVATAR: '/user/upload',

  VIP_LIST: '/vip/list',
  VIP_ADD: '/vip/add',

  LIKE_LIST: '/favorite/UserCollect',
  LIKE_REMOVE: '/like/remove',
  LIKE_DELETE: '/favorite/del',
  LIKE_ADD: '/favorite/add',
  LIKE_TYPE_LIST: '/like/type/list',
  LIKE_EDIT_TYPE: '/like/type/edit',
  LIKE_DELETE_TYPE: '/like/type/del',
  LIKE_ADD_TYPE: '/like/type/add',

  ORDER_LIST: '/orders/list',

  RECHARGE_ADD: 'orders/BuyGoods',

  MESSAGE_LIST: '/Email/information',
  EDIT_MESSAGE: '/Email/read',
  DEL_MESSAGE: '/Email/deletes',

  DEAL_LIST: '/Detail/transaction',

  RANK_LIST: '/MoneyRanking',

  MODEL_LIST: '/Commodity/lists',
  MODEL_TYPE: '/Commodity/types',
  MODEL_DETAIL: '/Commodity/details',
  MODEL_RECOMMOND: '/Commodity/recommend',

  AUTHOR_CONTACT: '/user/CheckContact',
  AUTHOR_INFO: '/author/info',

  CITY_LIST: '/getcity',
  AD_LIST: 'ad/list',
  SWIPER_LIST: 'slideshow',
  INSP_LIST: 'inspiration/list',
  TYPE_LIST: 'type/list',

  OTHER: 'others'
}
export const login = (data) => {
  return request({
    url: API.LOGIN,
    method: 'post',
    data
  })
}
export const loginByMobile = (data) => {
  return request({
    url: 'mobile',
    method: 'post',
    data
  })
}

export const updatePassword = (data) => {
  return request({
    url: 'user/UpdatePwd',
    method: 'post',
    data
  })
}
export const logout = (data) => {
  return request({
    url: API.LOGOUT,
    method: 'post',
    data
  })
}
export const getCode = (params) => {
  return request({
    url: API.GET_CODE,
    method: 'get',
    params
  })
}

export const resetPassword = (params) => {
  return request({
    url: API.RESET_PASSWORD,
    method: 'get',
    params
  })
}
export const getUserInfo = (params = {}) => {
  return request({
    url: API.GET_USER_INFO,
    method: 'get',
    params
  })
}
export const setUserInfo = (data) => {
  return request({
    url: API.SET_USER_INFO,
    method: 'post',
    data
  })
}
export const setUserName = (data) => {
  return request({
    url: 'user/UpdateName',
    method: 'post',
    data
  })
}
export const uploadAvatar = (data) => {
  return request({
    url: API.UPLOAD_USER_AVATAR,
    method: 'post',
    data
  })
}

// 交易明细
export const getDealList = (params) => {
  return request({
    url: API.DEAL_LIST,
    method: 'get',
    params
  })
}

// 我的订单
export const getOrderList = (params) => {
  return request({
    url: API.ORDER_LIST,
    method: 'get',
    params
  })
}

// 我的收藏
export const getLikeList = (params) => {
  return request({
    url: API.LIKE_LIST,
    method: 'get',
    params
  })
}
export const delLike = (data) => {
  return request({
    url: API.LIKE_DELETE,
    method: 'post',
    data
  })
}
export const removeLike = (data) => {
  return request({
    url: API.LIKE_REMOVE,
    method: 'post',
    data
  })
}
export const addLike = (data) => {
  return request({
    url: API.LIKE_ADD,
    method: 'post',
    data
  })
}

export const getLikeTypes = (params) => {
  return request({
    url: API.LIKE_TYPE_LIST,
    method: 'get',
    params
  })
}

export const addLikeType = (data) => {
  return request({
    url: API.LIKE_ADD_TYPE,
    method: 'post',
    data
  })
}
export const editLikeType = (data) => {
  return request({
    url: API.LIKE_EDIT_TYPE,
    method: 'post',
    data
  })
}
export const delLikeType = (data) => {
  return request({
    url: API.LIKE_DELETE_TYPE,
    method: 'post',
    data
  })
}

// 消息中心

export const getMessageList = (params) => {
  return request({
    url: API.MESSAGE_LIST,
    method: 'get',
    params
  })
}
export const editMessage = (data) => {
  return request({
    url: API.EDIT_MESSAGE,
    method: 'post',
    data
  })
}
export const delMessage = (data) => {
  return request({
    url: API.DEL_MESSAGE,
    method: 'post',
    data
  })
}

export const addRecharge = (data) => {
  return request({
    url: API.RECHARGE_ADD,
    method: 'post',
    data
  })
}

export const addVip = (data) => {
  return request({
    url: '/orders/BuyVip',
    method: 'post',
    data
  })
}


// 列表页
export const getModelList = (params) => {
  return request({
    url: API.MODEL_LIST,
    method: 'get',
    params
  })
}

export const getModelType = (params) => {
  return request({
    url: API.MODEL_TYPE,
    method: 'get',
    params
  })
}
// 
export const getAuthoContact = (params) => {
  return request({
    url: API.AUTHOR_CONTACT,
    method: 'get',
    params
  })
}

export const getModelDetail = (params) => {
  return request({
    url: API.MODEL_DETAIL,
    method: 'get',
    params
  })
}

export const getModelUrl = (params) => {
  return request({
    url: 'user/buy',
    method: 'get',
    params
  })
}

export const getAuthoInfo = (params) => {
  return request({
    url: API.AUTHOR_INFO,
    method: 'get',
    params
  })
}
export const getModelRecommond = (params) => {
  return request({
    url: API.MODEL_RECOMMOND,
    method: 'get',
    params
  })
}

export const getRankList = (params) => {
  return request({
    url: API.RANK_LIST,
    method: 'get',
    params
  })
}

export const getAdList = (params) => {
  return request({
    url: API.AD_LIST,
    method: 'get',
    params
  })
}
export const getSwiperList = (params) => {
  return request({
    url: API.SWIPER_LIST,
    method: 'get',
    params
  })
}
export const getInspList = (params) => {
  return request({
    url: API.INSP_LIST,
    method: 'get',
    params
  })
}

export const getTypeList = (params) => {
  return request({
    url: API.TYPE_LIST,
    method: 'get',
    params
  })
}

export const getOthers = (params) => {
  return request({
    url: API.OTHER,
    method: 'get',
    params
  })
}

export const getCity = (params) => {
  return request({
    url: API.CITY_LIST,
    method: 'get',
    params
  })
}

export const readAllMessage = (data) => {
  return request({
    url: 'Email/allread',
    method: 'post',
    data
  })
}

export const delAllMessage = (data) => {
  return request({
    url: 'Email/alldeletes',
    method: 'post',
    data
  })
}

export const getIsCollect = (params) => {
  return request({
    url: 'favorite/iscallect',
    method: 'get',
    params
  })
}

export const addUpload = (data) => {
  return request({
    url: 'user/houseadd',
    method: 'post',
    data
  })
}
export const editUpload = (data) => {
  return request({
    url: 'user/houseedit',
    method: 'post',
    data
  })
}
export const getMoney = (params) => {
  return request({
    url: 'GetMoney',
    method: 'get',
    params
  })
}
export const getVipMoney = (params) => {

  return request({
    url: 'GetVipMoney',
    method: 'get',
    params
  })
}

export const getUploadList = (params) => {
  return request({
    url: 'user/houselist',
    method: 'get',
    params
  })
}

export const changeUploadState = (data) => {
  return request({
    url: 'user/privacy',
    method: 'post',
    data
  })
}

export const generateOrder = (data) => {
  return request({
    url: 'orders/BuyGoods',
    method: 'post',
    data
  })
}

export const queryOrderState = (data) => {
  return request({
    url: 'orders/payment',
    method: 'post',
    data
  })
}

export const setWithdraw = (data) => {
  return request({
    url: 'user/SetWithdraw',
    method: 'post',
    data
  })
}

export const withdraw = (data) => {
  return request({
    url: '/user/withdraw',
    method: 'post',
    data
  })
}

export const addAppeal = (data) => {
  return request({
    url: '/orders/appeal',
    method: 'post',
    data
  })
}

export const editAppeal = (data) => {
  return request({
    url: '/orders/CheckAppeal',
    method: 'post',
    data
  })
}


export const resetLogin = (data) => {
  return request({
    url: '/user/RestLogin',
    method: 'post',
    data
  })
}

export const payCoin = (data) => {
  return request({
    url: '/orders/CoinPay',
    method: 'post',
    data
  })
}

export const getMessage = (data) => {
  return request({
    url: '/user/MessageNotification',
    method: 'post',
    data
  })
}

export const getWxLoginCode = (params) => {
  return request({
    url: 'getWeChatCode',
    method: 'get',
    params
  })
}

export const setWxLoginCallback = (params) => {
  return request({
    url: 'weChatCallback',
    method: 'get',
    params
  })
}

export const bindingWeChat = (data) => {
  return request({
    url: 'user/bindingWeChat',
    method: 'post',
    data
  })
}