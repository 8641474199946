import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index,
  },
  {
    path: "/list",
    name: "List",
    component: () => import('@/views/List.vue'),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import('@/views/auth.vue'),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import('@/views/Detail.vue'),
  },
  {
    path: "/member",
    name: "Member",
    component: () => import('@/views/Member/base.vue'),
    redirect: '/member/index',
    children: [
      {
        path: '/member/index',
        name: 'memberIndex',
        component: () => import('@/views/Member/index.vue')
      },
      {
        path: '/member/vip',
        name: 'memberVip',
        component: () => import('@/views/Member/vip.vue')
      },
      {
        path: '/member/likes',
        name: 'memberLikes',
        component: () => import('@/views/Member/likes.vue')
      },
      {
        path: '/member/order',
        name: 'memberOrder',
        component: () => import('@/views/Member/order.vue')
      },
      {
        path: '/member/message',
        name: 'memberMessage',
        component: () => import('@/views/Member/message.vue')
      },
      {
        path: '/member/deal',
        name: 'memberDeal',
        component: () => import('@/views/Member/deal.vue')
      },
      {
        path: '/member/info',
        name: 'memberInfo',
        component: () => import('@/views/Member/info.vue')
      },
      {
        path: '/member/upload',
        name: 'memberUpload',
        component: () => import('@/views/Member/upload.vue')
      },
      {
        path: '/member/uploadList',
        name: 'memberUploadList',
        component: () => import('@/views/Member/uploadList.vue')
      }
    ]
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
 }
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  if (token && !store.getters.userinfo) {
    await store.dispatch('getUserInfo');
    next();
  } else {

    next();
  }
})
export default router;
