import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store';
// 创建一个axios
axios.defaults.withCredentials = false

// 创建一个axios
// 请求拦截
const service = axios.create({
    timeout: 20 * 1000,
    withCredentials: false
})
function init() {
    service.defaults.baseURL = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_URL : window.globalConfig.VUE_APP_API_URL;
    service.interceptors.request.use(
        config => {
            const token = config.url === '/user/RestLogin' ? sessionStorage.getItem('token') : localStorage.getItem('token')
            console.log(config)

            if (token) {
                // 判断是否存在token，如果存在的话，则每个http header都加上token
                config.headers.t = `${token}`
                if (config.params) {
                    config.params.t = token;
                }
                if (config.data) {
                    config.data.t = token;
                }
            }
            return config
        },
        error => {
            console.log(error)
            return Promise.reject(error)
        }
    )
    // 响应拦截
    service.interceptors.response.use(
        response => {
            let res = response.data
            const code = res.code ? res.code : 200
            if (code !== 200) {
                if (res.code === 9998) {
                    console.log('未登录 >>', store);
                    store.commit('resetInfo')
                    location.reload();
                    // router.push({
                    //     path: '/'
                    // })
                } else if (res.code === 403) {
                    Message({
                        message: res.message || '用户权限不足',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    router.push({
                        path: '/login'
                    })
                } else if (res.code === 500) {
                    Message({
                        message: res.message || 'error',
                        type: 'error',
                        duration: 5 * 1000
                    })
                } else {
                    Message({
                        message: res.message || 'error',
                        type: 'error',
                        duration: 5 * 1000
                    })
                }
                return Promise.reject(response);
                // return false
            } else {
                console.log('res >>', res);
                return res.data
            }
        },
        error => {
            console.log(error)
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    )
}

service.init = init

export default service