<template>
  <div class="index-wrapper">
    <div class="swiper-box">
      <swiper
        @click.native="showBtn = true"
        :options="swiperOption"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="item in swiperList" :key="item">
          <img
            :src="$globalConfig.VUE_APP_IMAGE_URL + item"
            class="swiper-img"
          />
        </swiper-slide>
        <!-- <swiper-slide>456</swiper-slide>
        <swiper-slide>789</swiper-slide>-->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div
        class="custom-swiper-button-prev"
        :class="{ show: showBtn }"
        slot="button-prev"
      >
        <svg-icon icon-class="left"></svg-icon>
      </div>
      <div
        class="custom-swiper-button-next"
        :class="{ show: showBtn }"
        slot="button-next"
      >
        <svg-icon icon-class="right"></svg-icon>
      </div>
      <div class="search-wrapper">
        <el-input
          placeholder="搜索小区名称"
          v-model="searchForm.searchKey"
          clearable
          class="search-input input-with-select"
        >
          <!-- <el-select v-model="searchForm.city" slot="prepend" placeholder="请选择">
            <el-option
              :label="item.label"
              v-for="item in options"
              :key="item.value"
              :value="item.value"
            ></el-option>
          </el-select> -->
          <el-cascader
            v-model="searchForm.city"
            clearable
            :props="{ value: 'id', label: 'name', children: 'son' }"
            :show-all-levels="false"
            slot="prepend"
            placeholder="请选择"
            :options="options"
          ></el-cascader>
          <div slot="append" class="search-btn" @click="handleSearch">
            <svg-icon icon-class="search"></svg-icon>
          </div>
          <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
      </div>
      <!-- <div class="link-wrapper">
        <div class="link-item">CAD户型图</div>
        <div class="link-item">户型方案</div>
        <div class="link-item">户型效果</div>
        <div class="link-item">交流论坛</div>
        <div class="link-item">悬赏令</div>
      </div> -->
    </div>
    <!-- <div class="ad-swiper">
      <swiper :options="adSwiper" v-if="adList.length">
        <swiper-slide v-for="(item) in adList" :key="item.image">
          <div class="ad-item">
            <img :src="item.image" class="swiper-img" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="ad-swiper-button-prev" slot="button-prev">
        <svg-icon icon-class="left"></svg-icon>
      </div>
      <div class="ad-swiper-button-next" slot="button-next">
        <svg-icon icon-class="right"></svg-icon>
      </div>
    </div> -->
    <!-- <div class="floor-wrapper">
      <Title title="灵感鉴赏" subTitle="通过鉴赏,把握那一闪即逝的灵感"></Title>
      <Inspiration></Inspiration>
    </div>
    <div class="floor-wrapper">
      <Ranking v-model="rankingType" :list="rankingList[rankingType]" @change="handleChangeType"></Ranking>
    </div> -->
    <!-- <div class="floor-wrapper">
      <Title title="热门推荐" subTitle="设计师与户主交流的平台"></Title>
    </div>
    <div class="floor-wrapper">
      <Recommend title="户型方案区"></Recommend>
    </div>
    <div class="floor-wrapper">
      <Recommend title="施工效果区"></Recommend>
    </div>
    <div class="floor-wrapper">
      <Recommend title="下载排行区"></Recommend>
    </div>-->
  </div>
</template>

<script>
// import Title from "../components/Title.vue"
// import Recommend from "../components/Recommend.vue";
// import Inspiration from "../components/Inspiration.vue";
// import Ranking from "../components/Ranking.vue";
import { provinceAndCityDataPlus } from "element-china-area-data";
import { getRankList, getAdList, getSwiperList, getCity } from "@/api";
export default {
  data() {
    return {
      showBtn: false,
      searchForm: {
        searchKey: "",
        city: [],
      },
      swiperOption: {
        // slidesPerView: 2,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".custom-swiper-button-next",
          prevEl: ".custom-swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
      adSwiper: {
        slidesPerView: "auto",
        loop: true,
        centeredSlides: true,
        spaceBetween: 20,
        // 设置前进后退按钮
        navigation: {
          nextEl: ".ad-swiper-button-next",
          prevEl: ".ad-swiper-button-prev",
        },
      },
      options: provinceAndCityDataPlus,
      swiperList: [],
      adList: [],
      rankingType: "cad",
      rankingList: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    const res = await getSwiperList({});
    // console.log('res >>', res);
    // console.log('res.data >>', res.data);
    next((vm) => (vm.swiperList = res));
  },
  mounted() {
    // this.getAd();
    // this.getRank();
    this.getCity();
    this.$store.dispatch("getMessage");
  },
  methods: {
    async getCity() {
      const res = await getCity({});
      console.log("res >>", res);
      this.options = res;
    },
    async getSwiper() {
      const res = await getSwiperList({});
      this.swiperList = res.data;
    },
    async getAd() {
      const res = await getAdList({});
      this.adList = res.data;
    },
    async getRank() {
      const res = await getRankList({});
      this.rankingList = res;
    },
    handleChangeType(type) {
      this.rankingType = type;
      // this.getRank()
    },
    onSwiper() {},
    onSlideChange() {},
    handleSearch() {
      this.$router.push({
        path: "/list",
        query: {
          ...this.searchForm,
          area: this.searchForm.city[0],
          site: this.searchForm.city[1],
          city: undefined,
        },
      });
    },
  },
  // components: { Title, Inspiration, Ranking }
};
</script>

<style lang="scss" scoped>
.index-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #333;
}
.swiper-img {
  width: 100%;
}
.swiper-box {
  position: relative;
  .search-wrapper {
    position: absolute;
    top: 20%;
    z-index: 10;
    left: 50%;
    margin-left: -300px;
  }
  .search-input {
    width: 600px;
    // margin-left: 35px;
    padding: 8px 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    // border-bottom: 2px solid #e80000;
    ::v-deep {
      .el-input-group__prepend {
        background: transparent;
        border: none;
        position: relative;
        padding: 0;
        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 60%;
          width: 1px;
          background: #bbb;
          right: 0;
          top: 20%;
        }
      }
      .el-input--suffix {
        width: 100px;
      }
      .el-input__inner {
        background: transparent;
        border: none;
        // color: #f0f0f0;
      }
      .el-input-group__append {
        background: transparent;
        border: none;
        color: #fff;
      }
      .search-btn {
        // width: 32px;
        padding: 10px 32px;
        margin: -9px -20px;
        font-size: 20px;
        cursor: pointer;
        color: #fff;
        background: #f80000;
        height: 48px;
      }
    }
  }
  .link-wrapper {
    position: absolute;
    bottom: 40px;
    z-index: 10;
    display: flex;
    left: 20px;
    right: 20px;
    transform: translate(0%, 0);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    padding: 20px 0;
    .link-item {
      // width: 120px;
      color: #fff;
      font-size: 18px;
      width: 20%;
      text-align: center;
      padding: 8px 20px;

      cursor: pointer;
    }
    .link-item ~ .link-item {
      border-left: 1px solid #f6f6f6;
    }
  }
  .custom-swiper-button-prev,
  .custom-swiper-button-next {
    font-size: 60px;
    position: absolute;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    // color: #f0f0f0;
    color: transparent;
    &:hover {
      color: #f0f0f0;
      // background: rgba(0, 0, 0, 0.5);
    }
    &.show {
      color: #f0f0f0;
    }
  }
  .custom-swiper-button-prev {
    left: 4%;
  }
  .custom-swiper-button-next {
    right: 4%;
  }
  .swiper-container {
    height: 100vh;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 100px;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 30px;
    height: 4px;
    border-radius: 0;
    background: #fff;
    opacity: 0.7;
    &-active {
      background: #f80000;
      opacity: 1;
    }
  }
  ::v-deep
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 6px;
  }
}
.ad-swiper {
  padding: 20px 0;
  position: relative;
  .ad-swiper-button-prev,
  .ad-swiper-button-next {
    font-size: 24px;
    position: absolute;
    top: calc(50% - 30px);
    z-index: 1;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .ad-swiper-button-prev,
    .ad-swiper-button-next {
      background: rgba(0, 0, 0, 0.5);

      color: #f0f0f0;
    }
  }
  .ad-swiper-button-prev {
    left: 10px;
  }
  .ad-swiper-button-next {
    right: 10px;
  }
  ::v-deep .swiper-wrapper {
    width: 320px;
    height: 186px;
    .ad-item {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      &:hover img {
        transform: scale(1.1);
      }
    }
    img {
      width: 100%;
      height: 100%;
      transition: all 0.5s ease;
    }
  }
}
</style>
