<template>
  <el-dialog
    :visible.sync="dialogTableVisible"
    @close="handleClose"
    top="25vh"
    width="660px"
    append-to-body
    class="login-modal"
  >
    <div class="login-form-wrapper">
      <img class="form-img" src="@/assets/login_left.jpg" />
      <div class="login-form">
        <template v-if="type === 'login'">
          <div class="form-header">
            <div
              class="form-tab-item"
              :class="{ active: loginType === 'code' }"
              @click="changeLoginType('code')"
            >
              手机验证码登录
            </div>
            <div
              class="form-tab-item"
              :class="{ active: loginType === 'password' }"
              @click="changeLoginType('password')"
            >
              账号密码登录
            </div>
          </div>
          <el-form
            size="medium"
            class="form-content"
            :key="loginType"
            :model="loginForm"
            ref="loginForm"
            :rules="rules"
          >
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号码"
                v-model="loginForm.phone"
                maxlength="11"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-mobile-phone"
                ></i>
              </el-input>
            </el-form-item>
            <div class="code-wrapper" v-if="loginType === 'code'">
              <el-form-item class="mg-b0" prop="code">
                <el-input
                  placeholder="请输入验证码"
                  class="w-180"
                  v-model="loginForm.code"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-button
                class="code-btn"
                @click="handleCode('login')"
                :disabled="
                  !loginForm.phone ||
                  !/^1[3-9]\d{9}$/.test(loginForm.phone) ||
                  !!timer
                "
                >{{ tips }}</el-button
              >
            </div>
            <el-form-item
              class="mg-b0"
              v-if="loginType === 'password'"
              prop="password"
            >
              <el-input
                type="password"
                placeholder="请输入密码"
                show-password
                v-model="loginForm.password"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <div>
              <div class="code-tips">
                <el-tooltip v-if="loginType === 'code'">
                  <div slot="content">
                    1.手机号是否错误
                    <br />2.是否被360等软件拦截
                  </div>
                  <span>未收到验证码?</span>
                </el-tooltip>
                <span v-else @click="changeType('reset')">忘记密码?</span>
              </div>
              <el-checkbox
                size="medium"
                class="agree"
                v-model="loginForm.agree"
              >
                勾选代表您同意
                <a @click.stop="openModal">《用户协议》</a>
              </el-checkbox>
            </div>
            <el-form-item>
              <el-button
                :disabled="!loginForm.agree"
                class="block"
                type="primary"
                size="medium"
                @click="handleLogin"
                >{{ loginType === "code" ? "登录/注册" : "登录" }}</el-button
              >
            </el-form-item>
          </el-form>
          <div class="third-party">
            <el-divider>其他方式</el-divider>
            <div class="third-item-wrapper">
              <div class="third-item qq" v-pending>
                <svg-icon icon-class="qq"></svg-icon>
              </div>
              <div class="third-item wechat" @click="loginByWechat">
                <svg-icon icon-class="wechat"></svg-icon>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <el-form
            size="medium"
            class="form-content"
            key="reset"
            :rules="rules"
            :model="loginForm"
            ref="resetPwd"
          >
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号码"
                v-model="loginForm.phone"
                maxlength="11"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-mobile-phone"
                ></i>
              </el-input>
            </el-form-item>
            <div class="code-wrapper align-top">
              <el-form-item prop="code">
                <el-input
                  placeholder="请输入验证码"
                  class="w-180"
                  v-model="loginForm.code"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-button
                class="code-btn"
                @click="handleCode('reset')"
                :disabled="
                  !loginForm.phone ||
                  !/^1[3-9]\d{9}$/.test(loginForm.phone) ||
                  !!timer
                "
                >{{ tips }}</el-button
              >
            </div>
            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="请输入密码"
                show-password
                v-model="loginForm.password"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="mg-b0" prop="repetPassword">
              <el-input
                type="password"
                placeholder="请再次输入密码"
                show-password
                v-model="loginForm.repetPassword"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <div>
              <div class="code-tips">
                <span @click="changeType('login')">记起密码?</span>
              </div>
            </div>
            <el-form-item>
              <el-button
                class="block"
                type="primary"
                size="medium"
                @click="handleReset"
                >确认修改</el-button
              >
            </el-form-item>
          </el-form>
        </template>
      </div>
    </div>
    <el-dialog
      :visible.sync="wordModal"
      title="用户协议"
      height="60vh"
      center
      class="word-modal"
      append-to-body
    >
      <div class="word-info">
        <p>
          感谢您选择注册并使用户户型，在使用“户户型”网站服务前，请您认真阅读本协议，并确认承诺同意遵守本协议之全部约定。如果您对本协议的任何条款表示异议，你可以选择不进入户户型；进入户户型网站则意味着您将同意遵守本协议下全部规定，并完全服从于户户型的统一管理。
        </p>
        <p>一、本站服务条款的确认和接纳</p>
        <ul>
          <li>
            1.1
            “户户型”为泉州盛居科技有限公司注册商标，任何人未经泉州盛居科技有限公司许可不得以任何形式擅自使用。
          </li>
          <li>
            1.2
            户户型是泉州盛居科技有限公司为网友提供的信息存储空间，是供网友在线分享文档、视频、音频的开放平台。
          </li>
          <li>1.3 户户型所有权、经营权、管理权均属泉州盛居科技有限公司。</li>
          <li>1.4 本协议最终解释权归属泉州盛居科技有限公司。</li>
          <li>
            1.5
            户户型保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
          </li>
        </ul>

        <p>二、帐号注册及使用规则</p>
        <ul>
          <li>2.1 凡是注册用户和浏览用户均为户户型用户（以下统称“用户”）。</li>
          <li>
            2.2
            用户的个人信息受到保护，不接受任何个人或单位的查询。国家机关依法查询除外，用户的个人设置公开除外。
          </li>
          <li>2.3 用户享有言论自由的权利。</li>
          <li>
            2.4
            用户的言行不得违反《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》等相关法律规定，不得在户户型发布、传播或以其它方式传送含有下列内容之一的信息：
            1） 反对宪法所确定的基本原则的； 2）
            危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 3）
            损害国家荣誉和利益的； 4） 煽动民族仇恨、民族歧视、破坏民族团结的；
            5） 破坏国家宗教政策，宣扬邪教和封建迷信的； 6）
            散布谣言，扰乱社会秩序，破坏社会稳定的； 7）
            散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 8）
            侮辱或者诽谤他人，侵害他人合法权利的； 9）
            煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；10）以非法民间组织名义活动的；11)含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
            12）
            含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。
          </li>
          <li>2.5 用户不得在户户型内发布任何形式的广告。</li>
          <li>
            2.6
            用户应承担一切因其个人的行为而直接或间接导致的民事或刑事法律责任，因用户行为给泉州盛居科技有限公司造成损失的，用户应负责赔偿。
          </li>
          <li>
            2.7
            户户型拥有对违反本协议的用户进行处理的权力，直至禁止其在户户型上传及下载文档、视频、音频。
          </li>
          <li>
            2.8
            禁止任何使用刷分工具等非法软件进行批量、违规上传文档、视频、音频的行为。
          </li>
          <li>
            2.9
            任何用户发现文库文档、视频、音频内容涉嫌侮辱或者诽谤他人、侵害他人合法权益的或违反文库协议的，有权进行投诉。
          </li>
        </ul>
        <p>三 、用户权利</p>

        <li>
          3.1
          户户型是供网友在线分享文档、视频、音频的开放平台，为网友提供的信息存储空间，平台上所累积的文档、视频、音频，均来自热心用户的积极上传。对于用户上传的文档内容之真实性引发的全部责任，由用户自行承担。户户型的用户不能侵犯包括他人的著作权在内的知识产权以及其他权利。一旦由于用户上传的文档、视频、音频发生权利纠纷或侵犯了任何第三方的合法权益，其责任由用户本人承担，因此给户户型或任何第三方造成损失的，用户应负责全额赔偿。
        </li>
        <li>
          3.2
          户户型用户不得侵犯包括他人的著作权在内的知识产权以及其他合法权利。如果由于用户上传的文档、视频、音频未经著作权人同意擅自对他人的作品进行全部或部分复制，修改，改编，翻译，汇编等，有可能侵害到他人的著作权时，不得把相关内容上传发布到户户型。
        </li>
        <li>
          3.3
          用户承诺：其所上传的所有文档、视频、音频内容符合中国法律法规和规范性文件的相关规定，不侵犯任何第三方的合法权益。如用户违反前述保证，户户型有权删除相关文档内容，并可以暂停或终止向该用户提供服务。
        </li>
        <li>
          3.4
          对于用户上传到户户型上的任何内容，用户同意户户型在全世界范围内享有免费的、永久的、不可撤销的、非排他性的使用和再许可的权利。户户型享有修改、复制、发行、表演、展览、信息网络传播、改编、翻译、汇编等权利。同时，对于用户上传到户户型内容的侵权行为，用户授权户户型在全世界范围内进行维权。
        </li>
        <li>
          3.5
          如因户户型用户上传的内容侵犯了第三方的合法权利，第三方向户户型提出异议，户户型有权删除相关的内容。提出异议者和文档上传者之间的相关法律问题得到解决后，以此为依据，向户户型提出申请，户户型可以恢复被删除的内容。
        </li>
        <li>
          3.6
          当权利人发现户户型的内容侵犯其合法权益时，权利人应事先向知户户型站出“权利通知”，户户型将根据中国法律法规和规范性文件的规定采取移除相关内容等合理措施。
        </li>
        <li>
          3.7
          用户从户户型中获得的信息，未经事先许可，用户不得以盈利为目的自己或提供给第三方进行使用（符合法律规定的“合理使用”条件的除外）。否则，一切法律后果由该用户或第三方自负，与户户型无关。
        </li>
        <li>
          3.8
          未经泉州盛居科技有限公司事先许可，禁止使用任何机器人、蜘蛛、其他自动设备，或手动程序来监视或复制户户型页或其所包含的任何内容。否则，户户型有权依法追究法律责任。
        </li>

        <p>四 、 版权声明</p>

        <li>
          4.1
          户户型独立拥有或与相关内容提供者共同拥有设计本网站内相关内容（包括但不限于文字、图片、音频、视频资料、广告、域名、程序、专栏目录与名称、内容分类标准及页面设计、编排、软件等）的版权和或其他相关知识产权。
        </li>
        <li>
          4.2
          除非中国法律另有规定，未经户户型书面许可，对于户户型拥有版权或其他知识产权的任何内容，任何人不得复制或在非户户型所属的服务器上做镜像或以其他任何方式进行使用；
        </li>
        <li>
          4.3
          对于通过本网发布的作品，本网受著作权人委托禁止任何媒体、网站或个人在未经书面授权的情况下转载使用。
        </li>
        <li>违反上述声明者，户户型将依法追究其法律责任。</li>

        <p>五 、免责声明</p>
        <li>
          5.1
          本网站提醒您：所有用户在使用本网站前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用本网站，但如果您使用本网站，您的使用行为将被视为对本声明全部内容的认可。
        </li>
        <li>
          5.2
          本网站如因系统维护或升级而需暂停服务时，将事先公告。若因网络状况、通讯线路、第三方网站等任何原因或其他不可抗力而导致您不能正常使用本网站，对于暂停服务期间造成的一切不便与损失，本网站不负任何责任。
        </li>
        <li>
          5.3
          任何通过使用本网站而搜索链接到的第三方网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，本网站对其合法性概不负责，亦不承担任何法律责任。
        </li>
        <li>
          5.4
          本网站尊重并保护所有使用本网站用户的个人隐私权，您注册的用户名、电子邮件地址等个人资料，非经您亲自许可或根据相关法律、法规的强制性规定，本网站不会主动地泄露给第三方。本网站提醒您：您在使用搜索引擎时输入的关键字将不被认为是您的个人隐私资料。
        </li>
        <li>
          5.5
          本网站会在您自愿选择服务或提供信息的情况下收集您的个人信息，并将这些信息进行整合，以便向您提供更好的用户服务。请您在注册时及时、详尽及准确的提供个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，由您自行承担相应的后果。请您不要将您的帐号、密码转让或出借予他人使用。如您发现您的帐号遭他人非法使用，应立即通知本网站。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，本网站不承担责任。
        </li>
        <li>
          5.6
          用户上传文字、图片、音频、视频资料等作品须保证不存在任何权属纠纷，否则由此产生的纠纷由上传者承担法律责任。
        </li>
        <p>六 、处罚规则</p>

        <li>
          6.1
          泉州盛居科技有限公司郑重提醒用户，若出现下列情况任意一种或几种，将承担包括被关闭全部或者部分权限、被暂停或被删除其帐号的后果，情节严重的，还将承担相应的法律责任。
          使用不雅或不恰当ID和昵称；多次涉及侵犯他人著作权、名誉权等合法权利。发表含有猥亵、色情、人身攻击和反政府言论等非法或侵权言论的；从事非法商业活动；模仿户户型管理人员ID或者他人ID，用以假冒管理人员或破坏管理人员形象；使用非法软件进行批量、作弊下载文档、视频、音频的行为。其他泉州盛居科技有限公司认为不恰当的情况。
        </li>

        <li>
          6.2 凡文档出现以下情况之一，
          户户型管理人员有权不提前通知作者直接删除，并依照有关规定作相应处罚。情节严重者，户户型管理人员有权对其做出关闭部分权限、封禁直至删除其帐号。发表含有本协议第2.3条禁止发布、传播内容的文档、视频、音频；发表内容与主题无关，或者无内容的灌水文档、视频、音频；同一文档、视频、音频多次出现的；违反本协议第2.4条的规定，发布广告的；
          文档、视频、音频内容会包含有严重影响用户浏览的内容或格式的；严禁用户以任何方式转让、出售自己的户户型账号与下载券，一经发现，户户型有权立即封禁该账号；其他泉州盛居科技有限公司认为不恰当的情况。
        </li>

        <p>七、交易保障</p>

        <li>
          7.1
          在用户进行户户型平台交易过程中，户户型保障用户已支付的费用安全，并保用户上传后赚取的所得收入。
        </li>
        <li>
          7.2
          用户使用在线支付交纳咨商品费用时，支付成功后，该交易所得费用平台会进行48小时的考察期，平台才会与上传用户进行结算。
        </li>
        <li>
          7.3
          如果在用户支付成功后，发现商品存在问题，购买用户请及时联系平台申诉退款，平台再48小时内进行处理，申诉成功后，平台将本次所购买商品费用退还给用户。
        </li>
        <li>
          7.4
          考察期为48小时，考察期结束后，将上传用户所得费用计入用户账户，用户可进行提现，用户提现所产生的提现费用由用户承担。
        </li>
        <p>八 、其他</p>

        <li>
          8.1
          所有用户发表的文档、视频、音频而引起的法律纠纷，与泉州盛居科技有限公司无关。
        </li>
        <li>
          8.2
          户户型如因系统维护或升级等而需暂停服务时，将事先公告。若因硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，
          户户型不负任何责任。 由于
          户户型的调整导致信息丢失和/或其他结果的，泉州盛居科技有限公司不承担任何责任。
        </li>
        <li>
          8.3
          户户型有权根据中华人民共和国法律、法规及规范性文件的变化以及互联网及自身业务的发展情况，不断修改和完善包括文库协议在内的户户型帮助的相关条款。户户型保留随时修改文库协议在内的户户型帮助条款的权利。
        </li>
        <li>
          8.4
          本协议未涉及的问题参见国家有关法律法规，当本协议与国家法律法规冲突时，以国家法律法规为准。
        </li>
        <li>
          8.5
          户户型尊重用户的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，
          户户型将虚心接受并适时修改本协议及本站上的各类规则。
        </li>
      </div>
      <template #footer>
        <el-button @click="wordModal = false">我已了解</el-button>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="infoVisible"
      title="完善信息"
      width="400px"
      append-to-body
      center
      class="info-modal"
    >
      <el-form
        size="medium"
        class="form-content"
        key="reset"
        center
        :rules="resetRules"
        :model="resetForm"
        ref="resetForm"
      >
        <template v-if="needPhone">
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号码"
              v-model="resetForm.phone"
              maxlength="11"
            >
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <div class="code-wrapper align-top">
            <el-form-item prop="code">
              <el-input
                placeholder="请输入验证码"
                class="w-180"
                v-model="resetForm.code"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-mobile-phone"
                ></i>
              </el-input>
            </el-form-item>
            <el-button
              class="code-btn"
              @click="handleCode('login', 'resetForm')"
              :disabled="
                !resetForm.phone ||
                !/^1[3-9]\d{9}$/.test(resetForm.phone) ||
                !!timer
              "
              >{{ tips }}</el-button
            >
          </div>
        </template>
        <template v-if="needPwd">
          <el-form-item prop="pwd">
            <el-input
              type="password"
              placeholder="请输入密码"
              show-password
              v-model="resetForm.pwd"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="repwd">
            <el-input
              type="password"
              placeholder="请再次输入密码"
              show-password
              v-model="resetForm.repwd"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            class="block"
            type="primary"
            size="medium"
            @click="handleInfo"
            >确认修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog height="500px" width="550px"       append-to-body
      center :visible.sync="qrcodeVisible">
      <iframe :src="qrPath" width="500px" height="420px"></iframe>
      <!-- <VueQr :text="qrPath" :size="200"></VueQr> -->
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  login,
  resetPassword,
  getCode,
  loginByMobile,
  resetLogin,
  getWxLoginCode
} from "@/api";
export default {
  props: {
    formType: {
      type: String,
      default: "password",
    },
  },
  data() {
    let validRepet = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validRp = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogTableVisible: false,
      type: "login",
      loginType: this.formType,
      tips: "获取手机验证码",
      timer: null,
      time: 60,
      qrcodeVisible: false,
      qrPath: '',
      loginForm: {
        phone: "",
        code: "",
        password: "",
        repetPassword: "",
        agree: false,
      },
      wordModal: false,
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: '手机号格式错误',
          //   trigger: 'blur'
          // }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repetPassword: [{ validator: validRepet, trigger: "blur" }],
      },
      codeIds: {
        login: 1508230,
        reset: 1508663,
        register: 1508230,
      },
      infoVisible: false,
      resetForm: {
        phone: "",
        code: "",
        pwd: "",
        repwd: "",
      },
      resetRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: '手机号格式错误',
          //   trigger: 'blur'
          // }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repwd: [{ validator: validRp, trigger: "blur" }],
      },
      needPhone: false,
      needPwd: false,
    };
  },
  mounted() {
    this.dialogTableVisible = true;
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    loginByWechat() {
      this.qrcodeVisible = true;
      this.generateQR()
    },
    generateQR() {
      const {href} = this.$router.resolve({
        path: '/auth'
      })
      getWxLoginCode({
        // url: href,
        url: `${location.origin}/${href}`
      }).then((res) => {
        console.log('res >>', res);
        this.qrPath = res
      })
    },
    handleInfo() {
      this.$refs["resetForm"].validate(async (valid) => {
        if (valid) {
          if (!this.sendCode && this.needPhone) {
            this.$message.warning("请获取验证码!");
            return;
          }
          await resetLogin({
            phone: this.needPhone ? this.resetForm.phone : undefined,
            code: this.needPhone ? this.resetForm.code : undefined,
            pwd: this.needPwd ? this.resetForm.pwd : undefined,
            repwd: this.needPwd ? this.resetForm.repwd : undefined,
          });
          this.$message.success("设置成功");
          localStorage.setItem("token", sessionStorage.getItem("token"));
          sessionStorage.removeItem("token");
          location.reload();
        } else {
          this.$message.warning("请认真填写内容!");
          return false;
        }
      });
    },
    openModal() {
      this.wordModal = true;
    },
    async handleCode(type, form = "loginForm") {
      if (this.timer) return;
      await getCode({
        phone: this[form].phone,
        id: this.codeIds[type],
      });
      this.$message.success("验证码发送成功");
      this.sendCode = true;
      this.timer = setInterval(() => {
        this.time -= 1;
        this.tips = `${this.time}秒后重新获取`;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.time = 60;
          this.tips = "获取手机验证码";
        }
      }, 1000);
    },
    changeType(type) {
      if (this.type === type) return;
      this.type = type;
      this.sendCode = false;
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.tips = "获取手机验证码";
      this.loginForm = {
        phone: "",
        code: "",
        password: "",
        repetPassword: "",
        agree: false,
      };
    },
    changeLoginType(type) {
      if (this.loginType === type) return;
      this.loginType = type;
      this.sendCode = false;
      clearInterval(this.timer);
      this.timer = null;
      this.time = 60;
      this.tips = "获取手机验证码";
      this.loginForm = {
        phone: "",
        code: "",
        password: "",
        repetPassword: "",
        agree: false,
      };
    },
    handleLogin() {
      this.$refs["loginForm"].validate(async (valid) => {
        if (valid) {
          if (this.loginType === "code" && !this.sendCode) {
            this.$message.warning("请获取验证码!");
            return;
          }
          if (this.loginType === "code") {
            const res = await loginByMobile({
              phone: this.loginForm.phone,
              code: this.loginForm.code,
              // ...this.loginForm,
              // pwd: this.loginForm.password,
            });
            // debugger
            if (!res.PassWord || !res.phone) {
              this.needPhone = !res.phone;
              this.needPwd = !res.PassWord;

              sessionStorage.setItem("token", res.t);
              // this.dialogTableVisible = false;
              this.infoVisible = true;
            } else {
              localStorage.setItem("token", res.t);
              this.$message.success("登录成功");
              location.reload();
            }
            return;
          }
          const res = await login({
            ...this.loginForm,
            pwd: this.loginForm.password,
          });
          // debugger
          if (!res.PassWord || !res.phone) {
            this.needPhone = !res.phone;
            this.needPwd = !res.PassWord;

            sessionStorage.setItem("token", res.t);
            // this.dialogTableVisible = false;

            this.infoVisible = true;
          } else {
            this.$message.success("登录成功");
            localStorage.setItem("token", res.t);
            location.reload();
          }
        } else {
          this.$message.warning("请认真填写内容!");
          return false;
        }
      });
    },
    handleReset() {
      this.$refs["resetPwd"].validate(async (valid) => {
        if (valid) {
          if (!this.sendCode) {
            this.$message.warning("请获取验证码!");
            return;
          }
          await resetPassword({
            // ...this.loginForm,
            phone: this.loginForm.phone,
            code: this.loginForm.code,
            pwd: this.loginForm.password,
            RePwd: this.loginForm.repetPassword,
          });
          this.$message.success("修改成功");
          this.changeType("login");
        } else {
          this.$message.warning("请认真填写内容!");
          return false;
        }
      });
    },
    handleClose() {
      this.$store.commit("triggerLogin", false);
    },
  },
};
</script>

<style lang="scss">
.login-modal {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__headerbtn {
    top: 15px;
    right: 15px;
  }
  .login-form-wrapper {
    display: flex;
  }
  .form-img {
    width: 240px;
  }
  .login-form {
    flex: 1;
    padding: 50px 40px 0 40px;
    .form-header {
      display: flex;
      border-bottom: 1px solid #ededed;
      .form-tab-item {
        flex: 1;
        text-align: center;
        font-size: 16px;
        color: #666;
        padding-bottom: 14px;
        cursor: pointer;
        position: relative;
        &.active {
          color: #f80000;
          &::after {
            position: absolute;
            display: block;
            content: "";
            width: 60px;
            height: 2px;
            background: #f80000;
            bottom: 0;
            left: calc(50% - 30px);
          }
        }
      }
    }
  }
  .form-content {
    padding: 28px 0 0px 0;
  }
  .code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .code-btn {
      flex: 1;
      margin-left: 15px;
      height: 36px;
    }
  }
  .code-tips {
    text-align: right;
    padding: 10px 0;
    cursor: pointer;
    color: #999;
  }
  .agree {
    padding: 10px 0 15px;
    color: #333;
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #333;
    }
    a {
      color: #f80000;
    }
  }
  .third-party {
    width: 200px;
    margin: 0px auto;
    .el-divider__text {
      color: #666;
    }
    .third-item-wrapper {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    }
    .third-item {
      font-size: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f1f5f8;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.qq {
        color: #09b6f4;
      }
      &.wechat {
        color: #09bb07;
      }
    }
  }
}
.word-modal {
  .word-info {
    overflow: auto;
    height: 60vh;
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 10px 0;
    }
    li {
      font-size: 14px;
      line-height: 18px;
      // margin-left: 10px;
      margin-bottom: 6px;
    }
  }
  .el-dialog__body {
    padding: 5px 20px !important;
  }
}
.info-modal {
  z-index: 3000 !important;
  .form-content {
    // padding: 28px 0 0px 0;
  }
  .code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .code-btn {
      flex: 1;
      margin-left: 15px;
      height: 36px;
    }
  }
}
</style>
