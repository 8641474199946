import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo, logout, getMessage } from '@/api';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userinfo: null,
    loginVisible: false,
    messageCount: 0
  },
  mutations: {
    triggerLogin(state, visible) {
      state.loginVisible = visible
    },
    setUserInfo(state, info) {
      state.userinfo = info;
    },
    resetInfo(state) {
      state.userinfo = null;
      localStorage.removeItem('token')
    },
    setMessageCount(state, count) {
      state.messageCount = count
    }
  },
  actions: {
    async getUserInfo(context) {
      const res = await getUserInfo();
      context.commit('setUserInfo', res)
    },
    async logout(context) {
      await logout({});
      context.commit('resetInfo')
      location.reload();
    },
    async getMessage(context) {
      if (!context.state.userinfo) return
      const res = await getMessage({});
      context.commit('setMessageCount', res)
    }
  },
  getters: {
    loginVisible: (state) => state.loginVisible,
    userinfo: (state) => state.userinfo,
    messageCount: (state) => state.messageCount,
  },
});
