<template>
  <div class="header-wrapper">
    <div class="left">
      <a href>
        <img src="@/assets/logo.png" class="logo-img" />
      </a>
      <ul class="menu-list">
        <!-- <li @click="linkTo('/')">首页</li> -->
        <!-- <li @click="linkTo(`/list?type=1`)">
          <el-popover
            placement="bottom"
            title
            width="800"
            trigger="hover"
            :offset="150"
            popper-class="mt-25"
          >
            <a slot="reference">CAD户型图</a>
            <TypeList :list="list1"></TypeList>
          </el-popover>
        </li>
        <li @click="linkTo(`/list?type=2`)">
          <el-popover
            placement="bottom"
            title
            width="800"
            :offset="150"
            trigger="hover"
            popper-class="mt-25"
          >
            <a slot="reference">户型方案</a>
            <TypeList :list="list2"></TypeList>
          </el-popover>
        </li>
        <li @click="linkTo(`/list?type=4`)">
          <el-popover
            placement="bottom"
            title
            width="800"
            :offset="150"
            trigger="hover"
            popper-class="mt-25"
          >
            <a slot="reference">户型效果</a>
            <TypeList :list="list3"></TypeList>
          </el-popover>
        </li>
        <li v-pending>交流论坛</li>
        <li v-pending>悬赏令</li> -->
      </ul>
    </div>
    <div class="right">
      <ul class="item-list">
        <li @click="linkTo('/')">首页</li>
        <li @click="linkTo('/list', { filter: 1 })">CAD户型图</li>
        <li @click="linkTo('/list', { filter: 2 })">户型方案</li>
        <li @click="linkTo('/list', { filter: 3 })">户型效果</li>
        <li @click="linkTo('/list', { filter: 4 })">户型施工图</li>
        <li @click="linkTo('/list', { filter: 5 })">户型开工图</li>
        <li v-login @click="linkTo('/member/upload')">
          上传户型领取分红
          <!-- <svg-icon icon-class="upload"></svg-icon> -->
          <!-- <span class="mini li-span">上传户型领取分红</span> -->
        </li>

        <!-- <li @click="linkTo('/member/vip')">
          <el-popover placement="bottom" title width="500" trigger="hover">
            <div slot="reference" class="pop-vip">
              <svg-icon icon-class="vip"></svg-icon>
              <span class="li-span">VIP</span>
            </div>
            <VIP></VIP>
          </el-popover>
        </li>
        <li v-pending>
          <svg-icon icon-class="withdraw"></svg-icon>
          <span class="li-span">提现</span>
        </li>
        <li v-pending>
          <svg-icon icon-class="recharge"></svg-icon>
          <span class="li-span">充值</span>
        </li>
        <li v-login>
          <svg-icon iconClass="signin"></svg-icon>
          <span class="li-span">签到</span>
        </li> -->
        <template v-if="!$store.getters.userinfo">
          <li v-login @click="type = 'password'">登录</li>
          <li v-login @click="type = 'code'">注册</li>
        </template>

        <li :style="{ marginTop: '5px' }" v-else>
          <el-dropdown @command="handleCommond">
            <!-- <el-avatar :src="$store.getters.userinfo.avatar"></el-avatar> -->
            <div class="avatar-wrapper">
              <img class="avatar" src="@/assets/avatar.jpg" /><el-badge
                v-show="messageCount"
                :value="messageCount"
                :max="99"
              ></el-badge>
            </div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="/member/index"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item command="/member/vip">VIP</el-dropdown-item>
              <el-dropdown-item command="/member/index">提现</el-dropdown-item>
              <!-- <el-dropdown-item command="/member/index">充值</el-dropdown-item> -->
              <!-- <el-dropdown-item command="/member/index">签到</el-dropdown-item> -->
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <LoginModal v-if="modalVisible" :formType="type"></LoginModal>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal.vue";
import { mapGetters } from "vuex";
// import TypeList from '@/components/TypeList.vue';
import { getTypeList } from "@/api";
// import VIP from '@/components/vip.vue';
export default {
  components: { LoginModal },
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
      type: "code",
    };
  },
  computed: {
    ...mapGetters(["loginVisible", "messageCount"]),
    modalVisible() {
      return this.$store.getters.loginVisible;
    },
  },
  mounted() {
    // this.getType1();
    // this.getType2();
    // this.getType3();
  },
  methods: {
    linkTo(path, param) {
      this.$router.push({
        path,
        query: param,
      });
    },
    handleCommond(cmd) {
      if (cmd === "logout") {
        this.$store.dispatch("logout");
      } else {
        this.linkTo(cmd);
      }
    },
    async getType1() {
      const res = await getTypeList({});
      this.list1 = res.data;
    },
    async getType2() {
      const res = await getTypeList({});
      this.list2 = res.data;
    },
    async getType3() {
      const res = await getTypeList({});
      this.list3 = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.15));
  background: rgba(0, 0, 0, 1);
  z-index: 20;
  padding: 0 15px;
  .left {
    display: flex;
    align-items: center;
    .logo-img {
      margin-left: 30px;
      // width: 180px;
      height: 55px;
    }

    .menu-list {
      display: flex;
      list-style: none;
      color: #f0f0f0;
      li {
        margin-left: 45px;
        flex-shrink: 0;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .item-list {
    display: flex;
    list-style: none;
    color: #f0f0f0;
    li {
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 16px;
      cursor: pointer;
      .li-span {
        margin-top: 5px;
        // &.mini {
        //   font-size: 12px;
        // }
      }
      ::v-deep .pop-vip {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  .avatar-wrapper {
    position: relative;
  }
  .avatar {
    width: 48px;
    border-radius: 50%;
  }
  ::v-deep .el-badge {
    position: absolute;
    bottom: -4px;
    right: -4px;
  }
}
</style>
